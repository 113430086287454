import { User as Auth0User } from "@auth0/auth0-react";
import { TENANT_INFO_URL } from "@talkouttech/portal-shared";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Permissions } from "../domain/tenant";
import { Department, Organisation } from "./company";

export const PASSWORD_CHANGE_REQUIRED_CLAIM = "https://talkout.com/passwordChangeRequired";

export type TenantInfo = {
  [tenantId: string]: {
    permissions: string[];
    organisationId: string;
    organisationUserId: string;
  };
};

export type DecodedToken = JwtPayload & {
  permissions: Permissions;
  organisationUserId: string;
  [TENANT_INFO_URL]: TenantInfo;
  ["https://talkout.com/impersonatedBy"]?: string;
  ["https://reelyze.com/impersonatedByDisplayValue"]?: string;
  [PASSWORD_CHANGE_REQUIRED_CLAIM]?: boolean;
  [key: string]: unknown;
};

export interface Identity {
  token: string;
  tenantInfo: TenantInfo;
  permissions: string[];
  isAnonymous?: boolean;
  isImpersonated?: boolean;
  impersonatedBy?: string;
  impersonatedByDisplayValue?: string;
  passwordChangeRequired?: boolean;
}

export class User {
  token: string;
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  permissions: string[];
  tenantInfo: TenantInfo;

  constructor(
    token: string,
    email: string,
    email_verified: boolean,
    name: string,
    nickname: string,
    picture: string,
    sub: string,
    updated_at: string,
    permissions: string[],
    tenantInfo: TenantInfo
  ) {
    this.token = token;
    this.email = email;
    this.email_verified = email_verified;
    this.name = name;
    this.nickname = nickname;
    this.picture = picture;
    this.sub = sub;
    this.updated_at = updated_at;
    this.permissions = permissions;
    this.tenantInfo = tenantInfo;
  }

  static fromAuth0UserAndToken(json: Auth0User, token: string): User {
    const decodedToken = jwtDecode<DecodedToken>(token);

    const permissions = decodedToken["permissions"] || [];
    const tenantInfo: TenantInfo = decodedToken[TENANT_INFO_URL] || {};

    if (typeof json === "string") {
      const jsonUser = JSON.parse(json);
      return { ...jsonUser, permissions, token, tenantInfo };
    } else {
      let user = Object.create(User.prototype);

      const jsonUser = Object.assign(user, json);
      return { ...jsonUser, permissions, token, tenantInfo };
    }
  }
}

export enum InvitationStatus {
  accepted = "accepted",
  none = "none",
  pending = "pending"
}

export interface TenantUserResponse {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  role: string;
  organisationId: string;
  departmentId: string;
  platformUserId: string;
  organisationName: string;
  departmentName: string;
  picture?: string;
  invitationStatus?: InvitationStatus;
  invitationId?: string;
  services?: string[];
  phoneNumber: string;
  jobRole?: string;
  avatarUrl?: string;
  isMHFA?: boolean;
  isOnboardingComplete?: boolean;
  isSuspended: boolean;
  _updatedOn: string;
}
export interface TenantUserPayload {
  id?: string;
  tenant: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  role: string;
  services: string[];
  organisation: Organisation;
  department: Department;
}

export interface DeleteTenantUserPayload {
  organisation: string;
  name: string;
  userId: string;
  tenant: string;
}
export interface BulkUpdateUserPayload {
  formData: FormData;
  tenant: string;
}

export type AddUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  role: string;
  organisation: Organisation;
  department: Department;
};

export enum UserDetailsStats {
  followers = "followers",
  following = "following",
  kudos = "kudos",
  posts = "posts",
  reactions = "reactions"
}

export type UserService = "paths" | "worlds";

export type UserBadge = {
  imageUrl: string;
  userBadgeId: string;
};
export interface UserProfileSummary {
  interests: string[];
  aboutMe: string;
  aboutRole: string;
  homeLocation?: string;
  officeLocation?: string;
  dateOfBirth?: string;
  avatarUrl?: string;
  coverImageUrl?: string;
  departmentId: string;
  departmentName: string;
  displayName: string;
  firstName: string;
  id: string;
  email?: string;
  platformUserId: string;
  isOnboardingComplete?: boolean;
  lastName: string;
  organisationId: string;
  organisationName: string;
  jobRole?: string;
  role?: UserRole;
  userRole?: UserRole;
  services: UserService[];
  isSuspended: boolean;
  stats: Record<keyof typeof UserDetailsStats, number>;
  badge?: UserBadge;
  contentLanguageCode?: string;
}
export interface TenantsServicesRepsonse {
  activeServices: ActiveServices;
}
export interface ActiveServices {
  [key: string]: boolean;
}

export enum ContentType {
  TextPlain = "text/plain"
}

export interface UserPostContent {
  contentType: ContentType;
  data: string;
}

enum UserPostType {
  Text = "text",
  Video = "video",
  Image = "image"
}
export interface UserPost {
  audience: {
    displayName?: string;
    type: string;
  };
  type: UserPostType;
  content: UserPostContent[];
  isMandatory?: boolean;
  isResource?: boolean;
  disableComments?: boolean;
  disableReactions?: boolean;
  disableSharing?: boolean;
  postedBy: {
    avatarUrl?: string;
    displayName?: string;
    type: "user";
    organisationUserId: string;
  };
  _createdOn: string;
}

export enum UserRole {
  Admin = "admin",
  User = "user",
  Creator = "creator"
}

export enum UserFolowedType {
  Follower = "follower",
  Organisation = "organisation"
}

export interface FollowedUsers {
  type: UserFolowedType;
  id: string;
  displayName?: string;
  avatarUrl?: string;
  organisationId?: string;
  organisationName?: string;
  departmentName?: string;
  jobRole?: string;
  userRole?: string;
  badge?: UserBadge;
  isDeleted?: boolean;
}

export interface UserInterest {
  key: string;
  name: string;
  url: string;
}

export interface PlatformUser {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  identityProviders?: string[];
}

export interface FullUserBadge {
  id: string;
  name: string;
  description: string;
  category: string;
  levelImageUrl: string;
  badgeId: string;
  organisationUserId: string;
  badgeLevel: string;
  earnedDate: string;
  awardedDescription?: string;
  allowPinning?: boolean;
}

export interface Badge {
  id: string;
  name: string;
  description: string;
  category: string;
  defaultImageUrl: string;
  badgeLevels: Record<string, string>;
  allowPinning?: boolean;
}

export interface BadgeDisplayItem {
  id: string;
  name: string;
  description: string;
  category: string;
  defaultImageUrl: string;
  earned: boolean;
  level?: string;
}
