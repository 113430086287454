import { TENANT_INFO_URL } from "@talkouttech/portal-shared";
import { DecodedToken, Identity, PASSWORD_CHANGE_REQUIRED_CLAIM, TenantInfo } from "domain/user";
import jwtDecode from "jwt-decode";

const TOKEN_TYPE_CLAIM = "https://talkout.com/tokenType";
const ANON_TYPE = "anonymousSession";
const IMPERSONATION_TYPE = "impersonation";

export const getIdentyFromToken = (token: string): Identity => {
  const decodedToken = jwtDecode<DecodedToken>(token);

  const permissions = decodedToken["permissions"] || [];
  const tenantInfo: TenantInfo = decodedToken[TENANT_INFO_URL] || {};

  return {
    token,
    tenantInfo,
    permissions,
    isAnonymous: decodedToken[TOKEN_TYPE_CLAIM] === ANON_TYPE,
    isImpersonated: decodedToken[TOKEN_TYPE_CLAIM] === IMPERSONATION_TYPE,
    passwordChangeRequired: decodedToken[PASSWORD_CHANGE_REQUIRED_CLAIM],
    impersonatedBy:
      decodedToken[TOKEN_TYPE_CLAIM] === IMPERSONATION_TYPE
        ? decodedToken["https://talkout.com/impersonatedBy"]
        : undefined,
    impersonatedByDisplayValue:
      decodedToken[TOKEN_TYPE_CLAIM] === IMPERSONATION_TYPE
        ? decodedToken["https://reelyze.com/impersonatedByDisplayValue"]
        : undefined
  };
};
