import { Modal } from "components/Modal";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export type ModalContextType = {
  closeModal: () => void;
  backModal: () => void;
  isOpen: boolean;
  showModal: (
    title: string,
    children: React.ReactNode,
    modalSize?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined
  ) => void;
};

const ModalContext = React.createContext<ModalContextType>({
  showModal: () => {},
  closeModal: () => {},
  backModal: () => {},
  isOpen: false
});
// 👇 This is for loging readybility
ModalContext.displayName = "ModalContext";

interface ShowProps {
  show: boolean;
  children?: React.ReactNode;
  title: string;
  modalSize?: false | "sm" | "xs" | "md" | "lg" | "xl";
}

interface StackItem {
  children: React.ReactNode;
  title: string;
  modalSize?: false | "sm" | "xs" | "md" | "lg" | "xl";
}

function ModalProvider(props: any): JSX.Element {
  const [show, setShow] = React.useState<ShowProps>({
    show: false,
    title: "",
    children: null,
    modalSize: "sm"
  });
  const stack = React.useRef<StackItem[]>([]);
  const location = useLocation();
  const { t } = useTranslation();

  const showModal = useCallback(
    (title: string, children: React.ReactNode, modalSize?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined) => {
      stack.current = [...stack.current, { title, children, modalSize }];
      setShow({ show: true, title, children, modalSize: modalSize ?? "sm" });
    },
    [stack]
  );

  const closeModal = useCallback(() => {
    stack.current = [];
    setShow({ show: false, modalSize: "sm", children: show.children, title: "" });
  }, [show]);

  const backModal = useCallback(() => {
    stack.current.pop();
    if (stack.current.length > 0) {
      setShow({
        show: true,
        title: stack.current[stack.current.length - 1].title,
        children: stack.current[stack.current.length - 1].children,
        modalSize: stack.current[stack.current.length - 1].modalSize
      });
    } else {
      closeModal();
    }
  }, [closeModal]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(closeModal, [location.pathname]);

  const value = { showModal, closeModal, backModal, isOpen: show.show };

  return (
    <ModalContext.Provider value={value} {...props}>
      {props.children}
      <Modal
        open={show.show}
        title={t(show.title)}
        closeModal={closeModal}
        onBack={stack.current.length > 1 ? backModal : undefined}
        modalSize={show.modalSize ? show.modalSize : undefined}
      >
        <AnimatePresence>
          {stack.current.length > 1 ? (
            <motion.div
              initial={{ opacity: 0, x: 200 }}
              animate={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.2,
                  ease: [0.87, 0, 0.82, 1]
                }
              }}
              exit={{ opacity: 0 }}
            >
              {show.children}
            </motion.div>
          ) : (
            show.children
          )}
        </AnimatePresence>
      </Modal>
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error(`useModal must be used within a ModalProvider`);
  }
  return context;
}

// TODO: update other modals
export { ModalProvider, useModal };
